<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="createTag">
      <b-form
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2">
              {{ $t('CrearEtiquetas') }}
            </h4>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('Serie')"
              rules="required"
            >
              <b-form-group
                :label="$t('Serie')"
                label-for="account-series"
              >
                <v-select
                  v-model="serie"
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  :options="selectSeries"
                  :placeholder="$t('Serie')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('Cantidad')"
              rules="required"
            >
              <b-form-group
                :label="$t('Cantidad')"
                label-for="account-quantity"
              >
                <b-form-input
                  v-model="quantity"
                  type="number"
                  name="quantity"
                  :placeholder="$t('Cantidad')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            v-if="currentRole === 'admin_empresa' || currentRole === 'super_admin'"
            sm="12"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Cliente')"
              :rules="'required'"
            >
              <b-form-group
                :label="$t('Cliente')"
                label-for="account-clients"
              >
                <v-select
                  v-model="client"
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  :options="selectClients"
                  :placeholder="$t('Cliente')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Enviar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      name: '',
      client: '',
      serie: '',
      quantity: '',
    }
  },
  watch: {
    client() {
      const client_id = this.client ? this.client.id : ''
      this.searchSeries({ client_id })
    },
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      currentRole: 'auth/getRole',
      currentCompany: 'companies/getCurrentCompany',
      selectClients: 'clients/getSelectClients',
      selectSeries: 'series/getSelectSeries',
    }),
  },
  methods: {
    ...mapActions({
      create: 'tags/create',
      searchClients: 'clients/selectClients',
      searchSeries: 'series/selectSeries',

    }),
    handleSubmit() {
      this.$refs.createTag.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ tag: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('quantity', this.quantity)
      console.log(this.serie)
      data.append('serie_id', this.serie.id)
      if (this.currentRole === 'admin_empresa' || this.currentRole === 'super_admin') {
        data.append('client_id', this.client.id)
      } else {
        data.append('client_id', this.currentClient.id)
      }

      return data
    },
  },
  async created() {
    await this.searchClients({ company: this.currentCompany.id })

    if (this.currentClient) {
      this.client = this.currentClient
      await this.searchSeries({ client_id: this.client.id })
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
